import { SOSP_ID } from '../constants/members';
import { log } from './monitoring';
import { EditorSDK } from '../constants/types';

// We allow relayouting only for the widget in the SOSP
// If this action fails, we continue to santa members platform api as it will allow to relayout only in members pages
export const isWidgetInSOSP = async (editorSDK: EditorSDK, compId: string) => {
  try {
    const pwCompRef = await editorSDK.components.getById('', { id: compId });
    const pwAncestorsRefs = await editorSDK.components.getAncestors('', {
      componentRef: pwCompRef,
    });
    const firstAncestor = pwAncestorsRefs && pwAncestorsRefs[0];
    const hasSOSPParent =
      firstAncestor.id === SOSP_ID || firstAncestor.id === `${SOSP_ID}_1`;
    return hasSOSPParent;
  } catch (e) {
    log(
      `Failed to retrieve parent SOSP ancestor when changing layout, reason: ${e.toString()}`,
    );
    return true;
  }
};

export const setProfileCardWidth = async (
  editorSDK: EditorSDK,
  compId: string,
  width: number,
) => {
  try {
    const pwCompRef = await editorSDK.components.getById('', { id: compId });
    return editorSDK.document.components.layout.update('', {
      componentRef: pwCompRef,
      layout: { width },
    });
  } catch (e) {}
};
